import Keycloak from 'keycloak-js'
import { KEYCLOAK_CLIENT_ID, KEYCLOAK_REALM, KEYCLOAK_URL } from '../meta-env'

let keycloak: Keycloak | null = null
export let isAuthenticated = false

export async function initKeycloak() {
    if (keycloak) {
        return keycloak
    }
    const currentAppUrl = window.location.href
    keycloak = new Keycloak({
        url: KEYCLOAK_URL,
        realm: KEYCLOAK_REALM,
        clientId: KEYCLOAK_CLIENT_ID,
    })

    await keycloak.init({
        flow: 'standard',
        checkLoginIframe: false,
        onLoad: 'login-required',
        silentCheckSsoFallback: true,
        silentCheckSsoRedirectUri: currentAppUrl + '/silent-check-sso.html',
        redirectUri: currentAppUrl,
    })

    if (keycloak.authenticated) {
        isAuthenticated = true
    }
    return keycloak
}

export function getToken() {
    return keycloak?.updateToken(20).then(() => {
        return keycloak?.token
    })
}


